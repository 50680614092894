window.__tnt || (window.__tnt = {});
__tnt.comment || (__tnt.comment = {});

__tnt.comment.post = {

    /*
     * clear the comment form and hidden inputs
     */
    clear : function(form) {
        form.find('button,textarea').removeAttr('disabled');
        form.find('button[type=submit]').button('reset');
        form.find('.form-body').find('#comment-reply-preview').remove();
        form.find('input[name=parent_uuid]').remove();
        form.find('textarea[name=comment]').val('');
        form.find('.alert').text('').hide();
    },

    /*
     *  prepare the comment form to enter a comment
     */
    prepare : function(form, uuid) {

        if (uuid != '') {

            var comment = $('#comment-' + uuid).clone();
            comment.attr('id', 'comment-reply-preview');
            comment.find('.comment-options').remove();
            comment.find('.comment-footer').remove();
            comment.find('.replies').remove();
            comment.append('<hr/>');

            form.find('.modal-body').prepend(comment);

            // add the input to make the comment form submit a reply
            form.append("<input type=\"hidden\" name=\"parent_uuid\" value=\"" + uuid + "\"/>");

        }
    },

    /*
     * collect the form data
     */
    data : function(form) {

        var app = form.find('input[name=app]').val();
        var asset_uuid = form.find('input[name=id]').val();
        var parent_uuid = '';
        var text = '';

        if (form.find('textarea[name=comment]').val() != '') {
            text = form.find('textarea[name=comment]').val();
        }
        if (form.find('input[name=parent_uuid]').length) {
            parent_uuid = form.find('input[name=parent_uuid]').val();
        }

        this.obj = new Object({
            id : asset_uuid,
            app : app,
            comment : text,
            parent_uuid : parent_uuid
        });

        if (__tnt.user.authToken == null){
            if (form.find('input[name=alias]')){
                this.obj.alias = form.find('input[name=alias]').val();
            }
            if (form.find('input[name=tncms_captcha_id]')){
                this.obj.tncms_captcha_id = form.find('input[name=tncms_captcha_id]').val();
                this.obj.tncms_captcha_response = form.find('input[name=tncms_captcha_response]').val();
            }
            
            if (form.find('textarea.g-recaptcha-response')){
                this.obj['g-recaptcha-response'] = form.find('textarea.g-recaptcha-response').val();
            }
        }

        return this.obj;
    }
};

__tnt.comment.abuse = {
    prepare : function(form, uuid) {
        form.find('.modal-body').html("");

        var comment = $('#comment-' + uuid).clone();
        comment.attr('id', 'comment-report-preview');
        comment.find('.comment-options').remove();
        comment.find('.comment-footer').remove();
        comment.find('.replies').remove();

        form.find('.modal-body').prepend(comment);

        // set up the data in the form
        form.find('input[name=comment_id]').val(uuid);

    }
};

__tnt.comment.subscribe = {
    prepare : function(form){
        try{
            if(window.localStorage){
                if(localStorage.getItem('comment-subscribed-'+$('#asset-content').attr('data-asset-uuid'))){
                    __tnt.comment.subscribe.toggle();
                }
            }
        }catch(e){ }
    },

    toggle : function(){
        var form = $('#commentSubscribe');
        if(form.length){
            var action = form.attr('action');
            var actions = ['/subscribe/','/unsubscribe/'];

            if(action.search(/\/subscribe\//)<0){
                actions.reverse();
            }

            form.attr('action',$('#commentSubscribe').attr('action').replace(actions[0],actions[1]));
            $('#commentSubscribeModal').find('.subscribe, .unsubscribe').toggle();
            $('#comments').find('.watch span').toggle();
        }
    },

    set : function(){
        var uuid = $('#asset-content').attr('data-asset-uuid');
        try{
            if (window.localStorage) {
                localStorage.setItem('comment-subscribed-'+uuid, 1);
            }
        }catch(e){ }
    },

    unset : function(){
        var uuid = $('#asset-content').attr('data-asset-uuid');
        try{
            if (window.localStorage) {
                localStorage.removeItem('comment-subscribed-'+uuid);
            }
        }catch(e){ }
    }

};


__tnt.comment.preview = {
    append : function(previews, wrapper) {
        if (previews.length) {
            $.each(previews, function() {
                __tnt.comment.preview.create(this.data, $('#commentPostModal').find('.template.success'), wrapper);

            });
        }
    },

    create : function(data, preview, wrapper) {
        var insertBefore = wrapper.hasClass('desc');

        // sanitize the comment preview text
        var text = __tnt.comment.preview.sanitize(data.comment);

        // Handle getting replies into their correct places.
        if (data.parent_uuid != '') {
            var parent = $('#comment-' + data.parent_uuid);

            if (!parent.find('.replies').length) {
                parent.append('<div class="replies"></div>');
            }

            wrapper = parent.find('.replies').first();
        }

        // Array of Items to modify in the template
        var elements = [{
            'element' : 'img.avatar',
            'attr' : [{
                'attr' : 'src',
                'value' : __tnt.user.avatar
            }]
        }, {
            'element' : '.comment-text',
            'html' : '<p>' + text + '</p>'
        }];
        
        if(__tnt.user.authToken!=null){
            elements.push({
                'element' : '.username',
                'html' : __tnt.user.screenName
            });
        }else{
            if(data.alias!=""){
                elements.push({
                    'element' : '.username',
                    'html' : data.alias
                });
            }
        }
         

        // Handle Templates
        __tnt.template(preview[0], wrapper[0], insertBefore, elements, function(t){
            if(__tnt.user.avatar!=null){
                $(t).find('div.avatar').remove();
            }else{
                $(t).find('img.avatar').remove();
                if(__tnt.user.authToken==null){
                    $(t).find('div.avatar').find('i').removeClass('fa-user').addClass('fa-user-secret');
                    $(t).find('div.avatar').find('[data-fa-i2svg]').attr('data-icon', 'user-secret');
                }
            }
        });

        __tnt.emoticon.handle($('.comment.preview .comment-text'));

    },

    sanitize : function(string) {
        if ( typeof (string) == "string") {
            string = string.replace(/<?\/?script[\w\W\s=" :\']*?>/g, '');

        }
        return string;
    },

    store : function(data, wrapper) {
        var stored = false;

        try{
            if (window.sessionStorage) {
                //var previews = 'previews-' + $('section.comments').attr('id');
                var previews = new Array();
                var date = new Date();
                var timestamp = '';
                var count = 0;

                if (sessionStorage.getItem('comment-previews') != null) {
                    previews = JSON.parse(sessionStorage.getItem("comment-previews"));
                }

                // get the current time in a timestamp
                timestamp = date.getTime('u');

                // get the current number of posts by the user
                count = wrapper.find('.comment[data-user="' + __tnt.user.screenName + '"]').length + wrapper.find('.comment.preview').length;

                storedJSON = {
                    'c' : count,
                    't' : timestamp,
                    'data' : {
                        'comment' : data.comment,
                        'parent_uuid' : data.parent_uuid
                    }
                };

                previews.push(storedJSON);

                var uuid = $('#asset-content').attr('data-asset-uuid');
                try{
                    sessionStorage.setItem('comment-previews-'+uuid, JSON.stringify(previews));
                    stored = true;
                }catch(e){ }
            }
        }catch(e){ }

        return stored;
    },

    fetch : function() {
        var uuid = $('#asset-content').attr('data-asset-uuid');
        var sessionData = 'comment-previews-'+uuid;
        var previews = [];
        var fetched = '';

        try{
            if (window.sessionStorage) {
                // var previews = 'previews-' + $('section.comments').attr('id');
                fetched = sessionStorage.getItem(sessionData);

                if (fetched != null) {
                    previews = JSON.parse(fetched);
                }
            }
        }catch(e){ }
            
        return previews;
    },

    filter : function(previews, wrapper, store) {
        var uuid = $('#asset-content').attr('data-asset-uuid');
        var posts = wrapper.find('.comment[data-user="' + __tnt.user.screenName + '"]');
        var pageCount = posts.length;
        var date = new Date();
        var filtered = [];

        if ( typeof (previews) != "undefined") {
            var previews = __tnt.comment.preview.fetch();
        }

        if ( typeof (store) == "undefined") {
            store = false;
        }

        if(previews){
            $.each(previews, function() {
                var check = false;

                // If there are more comments on the page now by the user than there were at the time the comment was posted
                // Expire the preview
                if (this.c < pageCount) {
                    check = true;
                    //console.log("Expired Preview: Post Count");
                }

                // If the comment preview is older than 8 minutes
                // Expire the preview
                if (!check && ((date.getTime('u') - this.t) > 480000)) {
                    check = true;
                    //console.log("Expired Preview: Time Out");
                }

                if (!check) {
                    filtered.push(this);
                }
            });
        }

        if (store) {
            try{
                sessionStorage.setItem('comment-previews-'+uuid, JSON.stringify(filtered));
            }catch(e){ }
        }

        return filtered;
    },

    clear:function(){
        var uuid = $('#asset-content').attr('data-asset-uuid');
        try{
            sessionStorage.setItem('comment-previews-'+uuid, null);
        }catch(e){ }
    }
};



__tnt.comment.pending = {

    fetch : function(){
        var uuid = $('#comment-index').attr('data-asset-uuid');
        var sessionData = 'comment-changes-'+uuid;
        var changes = [];
        var fetched = '';

        try{
            if (window.sessionStorage) {
                fetched = sessionStorage.getItem(sessionData);

                if (fetched != null) {
                    changes = JSON.parse(fetched);
                }
            }
        }catch(e){ }
            
        return changes;
    },
    
    process : function(pending){
        if (pending.length) {
            $('.warning-changes-pending').show();
            $.each(pending, function(){
                var comment = $('#comment-' + this.data.comment_uuid);
                __tnt.comment.pending.mark(comment);
            });
        }
    },
    
    mark : function(comment){
        $('.warning-changes-pending').show();
        comment.find('.comment-body').append('<span class="label label-warning">Changes pending</span>');
        comment.siblings('.contribute-tools').find('button.edit, button.approve, button.deny').prop('disabled', true);
    },
    
    filter : function(changes, wrapper, store) {
        var posts = wrapper.find('.comment');
        var postCount = posts.length;
        var uuid = wrapper.data('asset-uuid');
        var date = new Date();
        var filtered = [];

        if ( typeof(changes) != 'undefined') {
            var changes = __tnt.comment.pending.fetch();
        }

        if ( typeof(store) == 'undefined') {
            store = false;
        }

        $.each(changes, function() {
            var check = false;

            // If there are more comments on the page now by the user than there were at the time the comment was posted
            // Expire the preview
            if (this.c < postCount) {
                check = true;
            }

            // If the comment preview is older than 8 minutes
            // Expire the preview
            if (!check && ((date.getTime('u') - this.t) > 480000)) {
                check = true;
            }

            if (!check) {
                filtered.push(this);
            }
        });

        if (store) {
            try{
                sessionStorage.setItem('comment-changes-'+uuid, JSON.stringify(filtered));
            }catch(e){ }
        }

        return filtered;
    },
    
    has_value : function(obj, key, value){
        return obj.hasOwnProperty(key) && obj[key] === value;
    },

    store : function(data, wrapper) {
        var stored = false;
        var count = 0;

        try{
            if (window.sessionStorage) {
                var uuid = $('#comment-index').attr('data-asset-uuid');
                var changes = new Array();
                var date = new Date();
                var timestamp = date.getTime('u');

                if (sessionStorage.getItem('comment-changes-'+uuid) != null) {
                    changes = JSON.parse(sessionStorage.getItem('comment-changes-'+uuid));
                }
                
                // get the current number of posts by the user
                count = wrapper.find('.comment').length;
                
                storedJSON = {
                    'c' : count,
                    't' : timestamp,
                    'data' : {
                        'comment_uuid' : data.comment_uuid
                    }
                };
                
                // check the comment about to be stored and see if it's already in session storage
                var hasVal = false;
                for(var i = 0; i < changes.length; i++){
                    if (__tnt.comment.pending.has_value(changes[i].data, 'comment_uuid', storedJSON.data.comment_uuid)){
                        hasVal = true;
                        break;
                    }
                }
                
                // if it's not in session storage, then store it
                if (hasVal === false){
                    changes.push(storedJSON);
                    
                    try{
                        sessionStorage.setItem('comment-changes-'+uuid, JSON.stringify(changes));
                        stored = true;
                    }catch(e){ }
                }
            }
        }catch(e){ }

        return stored;
    }
};


$(function() {
    // Pull in comments from session storage, filter out stale previews, and append good previews.
    if(__tnt.user.screenName==null || __tnt.user.authToken==null){
        __tnt.comment.preview.clear();    
    }else{
        previews = __tnt.comment.preview.filter(__tnt.comment.preview.fetch(), $('#comment-area'), true);
        __tnt.comment.preview.append(previews, $("#comment-area"));
    }
    
    // Pull in changes pending ids from session storage and take appropriate actions.
    if(window.location.pathname.indexOf('users/admin/business/comment') > -1 && __tnt.user.screenName != null){
        changes_pending = __tnt.comment.pending.filter(__tnt.comment.pending.fetch(), $('#comment-index'), true);
        __tnt.comment.pending.process(changes_pending);
    }

    /* Initialize Emoticons */
    __tnt.emoticon.handle($('.comment .comment-text'));

    $('#commentPost, #commentEdit').find('.comment-emoticons-selector').on('click', function(event){
        event.preventDefault();
    });

    __tnt.emoticon.handle($('#commentPost, #commentEdit').find('.emoticon-selector li a'));
    $('#commentPost, #commentEdit').find('.emoticon-selector li a').on('click',function(event){
       event.preventDefault();

       var emoticon = $(this).attr('title');
       var textarea = $('#commentPost, #commentEdit').find('textarea');

       textarea.val(textarea.val() + emoticon);
    });

    // On clicks on the comment body that aren't on a link display action buttons.
    // This will be unneeded on large devices.
    /* $('#comment-area .media.comment .comment-heading, #comment-area .media.comment .comment-body').on('click',function(){
        var comment = $(this).parents('.comment').first();

        if(comment.find('.comment-footer').hasClass('visible-lg')){
            // I may have to rethink this to better deal with resizing/rotating a screen. Maybe I shouldn't use visible-lg
            comment.find('.comment-footer').hide().removeClass('visible-lg');
        }
        comment.find('.comment-footer').first().toggle();
    }); */


    // Prepare the comment form, opitonally handling replies and open it as a modal
    $('a.comment-post').on('click', function(event) {
        event.preventDefault();

        // Remove the previous reply specific code if it exists, may speed things up to leave it there if it exists
        __tnt.comment.post.clear($('#commentPost'));

        var parent_uuid = '';
        if ( typeof ($(this).attr('data-comment-id')) != 'undefined') {
            parent_uuid = $(this).attr('data-comment-id');
        }
        __tnt.comment.post.prepare($('#commentPost'), parent_uuid);

        // open the bootstrap modal dialog
        $('#commentPostModal').modal({
            'backdrop' : 'static',
            'keyboard' : true,
            'show' : true,
            'remove' : false
        });
    });

    // handle posting a comment with ajax
    $('#commentPost').on('submit', function(event) {
        event.preventDefault();

        // disable the form to prevent the user changing their message mid-submit
        $('#commentPostModal').find('button,textarea').attr('disabled','');

        // display a spinner to prompt the user that something is happening
        $('#commentPostModal').find('button[type=submit]').button('loading');

        var data = __tnt.comment.post.data($('#commentPost'));

        if (data.comment != '') {

            $.ajax({
                type: "POST",
                url: $('#commentPost').attr('action'),
                data: data,
                success: function(data, status, request){
                    if(data.success){
                        // Track comment event
                        __tnt.trackEvent({
                            'network': 'Site',
                            'socialAction': 'comment',
                            'url': window.location.href.split('?')[0]
                        });
                        
                        // Close the popup
                        $('#commentPostModal').modal('hide');
                        
                        // remove the disabled attributes to re-enable the form
                        $('#commentPostModal').find('button,textarea').removeAttr('disabled');
                        
                        var data = __tnt.comment.post.data($('#commentPost'));
                        
                        // Store preview comment
                        __tnt.comment.preview.store(data, $('#comment-area'));
                        
                        // Display the comment preview
                        __tnt.comment.preview.create(data, $('#commentPost').find('.template.success'), $('#comment-area'));
                        
                        // scroll to the comment preview
                        
                        // Clear the form content
                        __tnt.comment.post.clear($('#commentPost'));
                    }else{
                        if (data.error.includes('update your profile')) {
                            var insert = '<a href="'+ __tnt.scrubUrl(window.location.origin) + '/users/admin/profile/edit/?mode=contact_info&referer_url='+ window.location.href +'" title="Update profile">update your profile</a>';
                            data.error = data.error.replace("update your profile", insert);                            
                        }
                        $('#commentPostModal').find('.alert.alert-info').html(data.error).show();
                        $('#commentPostModal').find('button,textarea').removeAttr('disabled');
                        $('#commentPostModal').find('button[type=submit]').button('reset');
                        $('#commentPostModal').modal('show');
                    }
                    
                    if (__tnt.user.authToken == null){
                        $('#commentPostModal').find('.tncms-captcha-image').trigger('click');
                        $('#commentPostModal').find('input[name=tncms_captcha_response]').val('');
                    }
                }
            });
        }
    });

    // initialize the report comment form
    $('#comments').find('.report').on('click', function(event) {
        event.preventDefault();

        __tnt.comment.abuse.prepare($('#commentReport'), $(this).attr('data-comment-id'));

        $('#commentReportModal').modal({
            'backdrop' : 'static',
            'keyboard' : true,
            'show' : true,
            'remove' : false
        });
    });

    $('#comments').find('.watch').on('click',function(event){
        event.preventDefault();

        $('#commentSubscribeModal').modal({
            'backdrop' : 'static',
            'keyboard' : true,
            'show' : true,
            'remove' : false
        });
    });


    __tnt.comment.subscribe.prepare();
    jQuery('#commentSubscribe').bind("submit",function(event){
        event.preventDefault();

        // Close the popup
        $('#commentSubscribeModal').modal('hide');

        $.post($('#commentSubscribe').attr('action'), {
            id : $('#commentSubscribe').find('input[name=id]').val(),
            app : $('#commentSubscribe').find('input[name=app]').val()
        }).done(function() {
            try{
                if(window.localStorage){
                    if(localStorage.getItem('comment-subscribed-'+$('#asset-content').attr('data-asset-uuid'))){
                        __tnt.comment.subscribe.unset();
                    }else{
                        __tnt.comment.subscribe.set();
                    }

                }
            }catch(e){ }

            __tnt.comment.subscribe.toggle();


        }).fail(function() { });
    });




    $('#comments').find('.comment-options').on('click', function(event) {
        event.preventDefault();
    });

    // report comments with ajax
    $('#commentReport').submit(function(event) {
        event.preventDefault();

        $('#commentReportModal').find('button').attr('disabled','');

        // display a spinner to prompt the user that something is happening
        $('#commentReportModal').find('button[type=submit]').button('loading');

        var abuseForm = $(this);
        var id = abuseForm.find("input[name=comment_id]").val();

        if (id != '') {

            var data = {
                action : 'comments:abuse',
                comment_id : id
            };

            var csrfToken = Cookies.get('tncms_csrf_token');
            if (csrfToken) data['tncms_csrf_token'] = csrfToken;

            $.post(abuseForm.attr('action'), data).done(function() {
                $('#commentReportModal').modal('hide');

                $('#commentReportModal').find('button[type=submit]').button('reset');
                abuseForm.find('input[name=comment_id]').val('');

                // Create Report Notice
                __tnt.template(document.getElementById('commentReportSuccessTemplate'), document.getElementById('comment-' + id), true);

                // Hide Reported Comment
                $('#comment-'+id).find('.comment-heading').first().hide();
                $('#comment-'+id).find('.comment-body').first().hide();
            }).fail(function() {
                __tnt.template(document.getElementById('commentReportErrorTemplate'), document.getElementById('#commentReport'), true);
            });
        }
    });
    
    
    
    // Prepare the comment edit form and open it as a modal
    $('button.comment-edit').on('click', function(event) {
        var comment = $(this).parents('.contribute-tools').siblings('.comment');
        var comment_text = comment.find('.comment-text p').html();
        
        $('#commentEditModal form textarea[name="content"]').val(comment_text.replace(/<br>/g,'\n'));
        $('#commentEditModal form input[name="commentid"]').val(comment.data('comment-uuid'));
        
        $('#commentEditModal').modal({
            'backdrop': 'static',
            'keyboard': true,
            'show': true,
            'remove': false
        });
    });
    

    // Handle editing a comment with ajax
    $('#commentEdit').on('submit', function(event) {
        event.preventDefault();
        
        var data = $('#commentEdit').serialize();

        // disable the form to prevent the user changing their message mid-submit
        $('#commentEditModal').find('button,textarea').prop('disabled', true);

        // display a spinner to prompt the user that something is happening
        $('#commentEditModal').find('button[type=submit]').button('loading');

        if (data.content != '') {
            $.ajax({
                type: "POST",
                url: $('#commentEdit').attr('action'),
                data: data,
                success: function(data, status, request){
                    // Close the popup
                    $('#commentEditModal').modal('hide');
                    
                    // remove the disabled attributes to re-enable the form
                    $('#commentEditModal').find('button,textarea').prop('disabled', false);
                    
                    var uuid = $('#commentEdit').find('input[name="commentid"]').val();
                    
                    __tnt.comment.pending.store({'comment_uuid': uuid}, $('#comment-index'));
                    __tnt.comment.pending.mark( $('#comment-'+uuid) );
                }
            });
        }
    });

    
    // Handle approve click
    $('#comment-index .contribute-tools .approve').click(function (e){
        var $this = $(this);
        var sCommentID = $this.parents('.contribute-tools').siblings('.comment').data('comment-uuid');
        var sAssetID = $('#comment-index').data('asset-uuid');
        var data = {
            submit:'approve',
            commentid:sCommentID,
            assetid:sAssetID
        };
        var csrfToken = Cookies.get('tncms_csrf_token');
        if (csrfToken) data['tncms_csrf_token'] = csrfToken;
        
        $.ajax({
            type: 'POST',
            url: location.href,
            data: data,
            success: function(data){
                __tnt.comment.pending.store({'comment_uuid': sCommentID}, $('#comment-index'));
                __tnt.comment.pending.mark($('#comment-'+sCommentID));
            }
        });
    });

    
    // Handle deny click
    $('#comment-index .contribute-tools a.deny').click(function (e){
        var $this = $(this);
        var sCommentID = $this.parents('.contribute-tools').siblings('.comment').data('comment-uuid');
        var sAssetID = $('#comment-index').data('asset-uuid');
        var sReason = $this.data('reason');
        var data = {
            submit:'deny',
            commentid:sCommentID,
            assetid:sAssetID,
            reason:sReason
        };
        var csrfToken = Cookies.get('tncms_csrf_token');
        if (csrfToken) data['tncms_csrf_token'] = csrfToken;
        
        $.ajax({
            type: 'POST',
            url: location.href,
            data: data,
            success: function(data){
                __tnt.comment.pending.store({'comment_uuid': sCommentID}, $('#comment-index'));
                __tnt.comment.pending.mark($('#comment-'+sCommentID));
            }
        });
    });

});
